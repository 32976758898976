import { Outlet } from "react-router-dom"
import { Modal, Typography } from '@mui/material'
import { Box } from "@mui/system";

const Layout = () => {
    const open = true;

    return (
        <main className="App">
            <Outlet />
            <Modal open={open} onClose={() => {}}>
                <Box sx={{
                height: '50vh',
                width: '50vw',
                top: '50%',
                left: '50%',
                bgcolor: 'rgb(255,255,255)',
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                opacity: 1,
                borderRadius: '10px',
                p: 5,
                m: 2
            }}>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        Indisponible
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Le site web Lizaora est actuellement en cours de développement.
                    </Typography>
                </Box>
            </Modal>
        </main>
    )
}

export default Layout
